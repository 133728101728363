/* ===============================================
==================== COULEURS ====================
=============================================== */

$vert-clair:#9bcb39;
$vert-fonce: #548323;
$gris-clair:#eee;
$gris-fonce:#7c7c7c;
$orange:#ff9800;

/* ===================================================
==================== TYPOGRAPHIES ====================
=================================================== */

@font-face {
    font-family: 'Apple Garamond Light-Italic';
    src: url('../../fonts/applegaramond-lightitalic-webfont.eot');
    src: url('../../fonts/applegaramond-lightitalic-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/applegaramond-lightitalic-webfont.woff2') format('woff2'), url('../../fonts/applegaramond-lightitalic-webfont.woff') format('woff'), url('../../fonts/applegaramond-lightitalic-webfont.ttf') format('truetype'), url('../../fonts/applegaramond-lightitalic-webfont.svg#apple_garamond_lightitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'icomoon';
    src: url('../../fonts/icomoon.eot?kcduk0');
    src: url('../../fonts/icomoon.eot?kcduk0#iefix') format('embedded-opentype'), url('../../fonts/icomoon.ttf?kcduk0') format('truetype'), url('../../fonts/icomoon.woff?kcduk0') format('woff'), url('../../fonts/icomoon.svg?kcduk0#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* =============================================
==================== MIXINS ====================
============================================= */

@mixin grille-produits($nombre_colonnes) {
    width: 100/$nombre_colonnes+%;
    &:nth-of-type(#{$nombre_colonnes+n}), &:last-of-type {
        .left-block .product-image-container {
            border-right: solid 1px $gris-clair;
            padding-bottom: 1px;
        }
    }
}

/* ==============================================
==================== GÉNÉRAL ====================
============================================== */

body {
    overflow-x:hidden;
    max-width:100%;
}
#page {
    * {
        text-shadow: none !important;
        &:focus {
            text-decoration: none;
        }
    }
    a, p, h1, h2, h3, h4, h5, li {
        font-family: 'Open Sans';
        color: black;
    }
    .rte a {
        color:$vert-clair;
        &:hover{
            color:$orange;
        }
    }
    .titre_accueil {
        font-family: 'Apple Garamond Light-Italic' !important;
        font-size: 34px;
        margin-bottom: 20px;
    }
    .product_list {
        &.grid {
            padding: 0 15px;
            .ajax_block_product {
                float: left;
                padding: 0 !important;
                clear: none !important;
                overflow: hidden;
                @media (min-width:1200px) {
                    @include grille-produits(5);
                }
                @media (min-width:992px) and (max-width:1199px) {
                    @include grille-produits(4);
                }
                @media (min-width:768px) and (max-width:991px) {
                    @include grille-produits(3);
                }
                @media (min-width:420px) and (max-width:767px) {
                    @include grille-produits(2);
                }
                @media (max-width:419px) {
                    @include grille-produits(1);
                }
                &:hover .product-container {
                    box-shadow: none;
                    .right-block .button-container {
                        display: block !important;
                    }
                }
                .left-block {
                    .product-image-container {
                        border: solid 1px $gris-clair;
                        border-right: none;
                        margin: 0;
                        padding: 0;
                        [src$="default.jpg"] {
                            visibility: hidden;
                        }
                    }
                    .content_price {
                        display: none;
                    }
                }
                .right-block {
                    height: 180px;
                    overflow: hidden;
                    padding: 12px 12px 0;
                    * {
                        padding: 0;
                        margin: 0;
                        text-align: left;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .bloc-fixe {
                        height: 80px;
                        * {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    h5 {
                        min-height: 0;
                        line-height: 1;
                        .product-name {
                            line-height: 1;
                        }
                    }
                    .content_price {
                        display: block;
                        .product-price {
                            font-weight: bold;
                            display: inline-block;
                            float: left;
                        }
                    }
                    .availability {
                        display: block;
                        float: right;
                        span {
                            border: none;
                            padding: 0;
                            background: none;
                            &.label-success {
                                color: $vert-clair;
                            }
                            &.label-warning {
                                color: $orange;
                            }
                        }
                    }
                    .button-container {
                        display:none !important;
                        .button {
                            border: none;
                            padding: 8px 0;
                            margin-top: 8px;
                            transition: .3s;
                            span {
                                background: none;
                                border: none;
                                text-align: center;
                            }
                            &.ajax_add_to_cart_button {
                                float: right;
                                width: 73%;
                                background: $orange;
                                border: solid 1px transparent;
                                span {
                                    color: white;
                                }
                                &:hover {
                                    span {
                                        color: black;
                                    }
                                }
                            }
                            &.lnk_view {
                                float: left;
                                width: 24%;
                                background: none;
                                border: solid 1px lightgray;
                                span {
                                    color: #7c7c7c;
                                }
                                &:hover {
                                    border-color: $vert-clair;
                                    span {
                                        color: $vert-clair;
                                    }
                                }
                            }
                        }
                    }
                }
                .functional-buttons {
                    display: none;
                }
            }
        }
        &.list {
            .ajax_block_product {
                text-align:left;
                .product-container {
                    padding:12px 0;
                    >.row {
                        display:flex;
                        flex-wrap:wrap;
                        align-items:center;
                        .left-block {
                            .new-box,
                            .sale-box {
                                display:none;
                            }
                            .product-image-container {
                                max-width:none;
                                padding:0;
                                border:none;
                                img {
                                    width:auto;
                                    max-height:100px;
                                    margin:0;
                                    &[src$="default.jpg"] {
                                        display:none;
                                    }
                                }
                            }
                        }
                        .center-block {
                            .product-flags {
                                margin:0;
                            }
                            h5 {
                                margin:0;
                                padding:0;
                                +p {
                                    float:left;
                                    margin-right:4px;
                                }
                            }
                            p {
                                margin:0;
                                &:nth-of-type(2):before {
                                    content:"|";
                                }
                                &.product-desc {
                                    margin-top:8px;
                                    text-align:justify;
                                }
                            }
                            .color-list-container {
                                margin:0;
                            }
                        }
                        .right-block {
                            .right-block-content {
                                display: flex;
                                align-items: center;
                                border: none;
                                margin: 0 -15px;
                                padding: 0;
                                div {
                                    padding: 0 15px;
                                    .price {
                                        margin: 0 0 3px;
                                    }
                                    &.button-container {
                                        display: none;
                                        .button {
                                            clear: none;
                                            margin: 0;
                                            transition: .3s;
                                            span {
                                                background: none;
                                                border: none;
                                                text-align: center;
                                                padding: 0;
                                                font-size: 16px;
                                                line-height: 38px;
                                            }
                                            &.ajax_add_to_cart_button {
                                                float: right;
                                                width: 73%;
                                                background: $orange;
                                                border: solid 1px transparent;
                                                span {
                                                    color: white;
                                                }
                                                &:hover {
                                                    span {
                                                        color: black;
                                                    }
                                                }
                                            }
                                            &.lnk_view {
                                                float: left;
                                                width: 24%;
                                                background: none;
                                                border: solid 1px lightgray;
                                                span {
                                                    color: #7c7c7c;
                                                }
                                                &:hover {
                                                    border-color: $vert-clair;
                                                    span {
                                                        color: $vert-clair;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .availability {
                                    span {
                                        background: none;
                                        border: none;
                                        font-size: 14px;
                                        padding: 0;
                                        margin: 0;
                                        &.label-success {
                                            color: $vert-clair;
                                        }
                                        &.label-warning {
                                            color: $orange;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover,
                &:focus {
                    .button-container {
                        display: block !important;
                    }
                }
            }
        }
    }
    .new-box, .sale-box {
        top: -1px;
        span {
            font-size: 13px;
            top: 18px;
            &::before,
            &::after {
                display: none;
            }
        }
    }
    .new-box {
        left: -1px;
        span {
            background: $orange;
        }
    }
    .sale-box {
        right: -1px;
        span {
            background: $vert-clair;
        }
    }
    .selector {
        position:relative;
        min-width:80px;
        height:30px;
        background:none;
        border:solid 1px lightgray;
        padding:0;
        span {
            width:100% !important;
            height:100%;
            background:white;
            padding:0 42px 0 12px;
            color:black;
            &::after {
                content:"\f107";
                font-weight:400;
                font-size:16px;
                font-family:'FontAwesome';
                color:#777;
                text-align:center;
                display:block;
                width:30px;
                height:100%;
                border-left:solid 1px lightgray;
                position:absolute;
                top:0;
                right:-1px;
            }
        }
        &:hover {
            span {
                &::after {
                    background:darken(white, 3%);
                    color:black;
                }
            }
        }
        select {
            float:right;
            height:100%;
            cursor:pointer;
        }
    }
    .button {
        background: none;
        border: none;
        padding: 0;
        span {
            font-weight: normal;
            border-radius: 0;
            transition: .3s;
            .icon-chevron-left {
                &::before {
                    content: "\f104"
                }
            }
        }
        &.button-small {
            span {
                padding: 8px 12px;
                border-color: lightgray;
                text-transform: uppercase;
                color: #7c7c7c;
                &:hover {
                    background: none;
                    border-color: $vert-clair;
                    color: $vert-clair;
                }
            }
        }
        &.button-medium {
            span {
                font-size: 16px;
                padding: 12px 25px;
                background: $vert-clair;
                border: none;
                color: white;
                i {
                    display: none;
                }
                &:hover {
                    color: black;
                }
            }
        }
    }
    .alert {
        background: none;
        border: none;
        padding: 0;
        margin: 0 0 20px !important;
        font-weight: normal;
        &.alert-warning,
        &.alert-danger {
            color: $orange;
        }
        &.alert-success {
            color: $vert-clair;
        }
    }
    .breadcrumb {
        margin: 10px 0 15px;
        background: none;
        border: none;
        padding: 0;
        color: black;
        font-weight: normal;
        a {
            width: auto;
            height: 10px;
            margin: 0;
            padding: 0;
            .icon-home {
                margin-right: 4px;
            }
            &::before {
                display: none;
            }
            &::after {
                display: inline;
                content: "\f105";
                font-family: 'FontAwesome';
                font-size: 16px;
                font-weight: normal;
                background: none;
                border: none;
                position: relative;
                left: 0;
                right: 0;
                top: 1px;
                margin: 0 6px;
            }
        }
        span {
            font-family: 'Open Sans';
            &.navigation-pipe {
                display: none;
            }
        }
    }
    .page-heading {
        border:none;
    }
}

/* =============================================
==================== HEADER ====================
============================================= */

#header {
    background: #548323;
    padding: 0;
    >.container {
        display:flex;
        flex-wrap:wrap;
        align-items:center;
    }
    #links_block_left {
        order:1;
        flex:0 1 100%;
        margin:15px 0 -4px;
        .title_block {
            display:none;
        }
        .list-block ul {
            display:flex;
            flex-wrap:wrap;
            justify-content:flex-end;
            margin:0;
            @media (max-width:991px) {
                justify-content:center;
                max-width:500px;
                margin:0 auto;
            }
            li {
                display:inline-block;
                margin-left:15px;
                padding:0;
                border:none;
                line-height:10px;
                a {
                    font-weight:normal;
                    font-size:12px;
                    color:$vert-clair;
                    text-decoration:none;
                    text-transform:uppercase;
                    transition:.3s;
                    &:before {
                        position:relative;
                        content:"";
                        background-position:center;
                        background-repeat:no-repeat;
                        background-size:100%;
                        display:inline-block;
                        width:22px;
                        height:18px;
                        top:4px;
                    }
                    &:hover {
                        color:white;
                    }
                }
                &:nth-of-type(1) a:before,
                &:nth-of-type(4) a:before {
                    top:4px;
                    margin-right:4px;
                    background-image:url('../../img/ico_header1.png');
                }
                &:nth-of-type(2) a:before,
                &:nth-of-type(5) a:before {
                    top:4px;
                    margin-right:3px;
                    background-image: url('../../img/ico_header2.png');
                }                 
                &:nth-of-type(3) a:before,
                &:nth-of-type(6) a:before {
                    top:3px;
                    margin-right:2px;
                    background-image: url('../../img/ico_header3.png');
                }
            }
        }
    }
    #header_logo {
        order:2;
        margin-top:40px;
        padding:0;
        text-align:center;
        a {
            display:block;
        }
    }
    #search_block_top {
        order:3;
        flex:1 1 0%;
        margin-top:40px;
        padding:0 50px 0;
        >div {
            max-width:480px;
            margin:0 auto;
            p {
                margin-bottom:6px;
                font-style:italic;
                font-weight:lighter;
                color:white;
            }
            #searchbox {
                display:flex;
                .search_query {
                    height:40px;
                    margin:0;
                    padding:0 15px;
                    background:white;
                    border:none;
                    box-shadow:none;
                    font-size:14px;
                }
                .button-search {
                    width:50px;
                    padding:0;
                    background:$orange;
                    line-height:40px;
                    transition:0.3s;
                    &:before {
                        font-size:18px;
                    }
                    &:hover {
                        color:black;
                    }
                }
            }
        }
    }
    #header_buttons {
        order:4;
        width:25%;
        margin-top:40px;
        .user_info,
        .shopping_cart {
            >a {
                display:block;
                height:40px;
                padding:0;
                text-align:center;
                font-weight:normal;
                font-size:14px;
                line-height:39px;
                color:white;
                transition:0.3s;
                &:before {
                    padding-right:5px;
                    font-size:18px;
                    color:inherit;
                    -webkit-font-smoothing:antialiased;
                }
                &:hover {
                    color:black;
                }
            }
        }
        .user_info>a {
            background:$vert-clair;
            &:before {
                content:"\f007";
                font-family:"FontAwesome";
            }
        }
        .shopping_cart {
            width:100%;
            padding-top:10px;
            >a:first-child {
                background:$orange;
                span {
                    font-size:12px;
                    &:first-of-type {
                        padding-left:2px;
                    }
                }
                &:after {
                    display:none;
                }
            }
            .cart_block {
                top:inherit;
                width:100%;
                min-width:250px;
                padding:20px;
                background:white;
                border:solid 1px lightgray;
                border-top:none;
                .products {
                    margin:0;
                    dt {
                        padding:0 0 15px;
                        background:none;
                        .cart-images {
                            margin-right:12px;
                            border:solid 1px lightgray;
                            width:60px;
                            img {
                                width:100%;
                            }
                        }
                        span,
                        a {
                            color:black;
                        }
                        .remove_link {
                            right:0;
                            top:0;
                            transition:.3s;
                            &:hover a {
                                color:$orange;
                            }
                        }
                    }
                }
                .cart-prices {
                    padding:0;
                    background:none;
                    .cart-prices-line {
                        padding:0;
                        &.first-line {
                            border-color:lightgray;
                        }
                        &.last-line {
                            margin:10px 0;
                            span {
                                font-weight:normal;
                                color:black;
                            }
                        }
                    }
                    p {
                        display:none;
                    }
                }
                .cart-buttons {
                    padding:0;
                    #button_order_cart {
                        margin:0;
                        span {
                            padding:10px 0;
                            background:$orange;
                            border:none;
                            color:white;
                            text-transform:none;
                            transition:.3s;
                            i {
                                display:none;
                            }
                            &:hover {
                                color:black;
                            }
                        }
                    }
                }
            }
        }
    }
    #layer_cart {
        top:30px !important;
        left:0;
    }
    #block_top_menu {
        order:5;
        .menu-content .submenu-container>li>ul>li a {
            text-transform:capitalize;
            &:before {
                width:12px;
                margin-right:4px;
                padding:0 0 0 5px !important;
                background-color:$vert-clair;
                border-radius:50%;
                line-height:12px;
                color:white;
                transition:0.3s;
            }
            &:hover:before {
                background-color:$orange;
            }
        }
    }
    @media (min-width:768px) {
        #block_top_menu {
            margin-top:40px;
            padding:0;
            .menu-content {
                display:flex;
                flex-wrap:wrap;
                justify-content:center;
                background:none;
                border:none;
                &:before {
                    position:absolute;
                    top:0;
                    left:50%;
                    content:"";
                    display:block;
                    width:100vw;
                    height:100%;
                    margin-left:-50vw;
                    background:$vert-clair;
                    z-index:-1;
                }
                >li {
                    float:none;
                    margin:0 24px 0 0;
                    border:none;
                    &:last-of-type {
                        margin:0;
                    }
                    a {
                        border:none;
                        background:none;
                        font-size:12px;
                        font-weight:normal;
                        padding:0;
                        transition:.3s;
                        &:hover {
                            color:$orange;
                        }
                    }
                    >a {
                        position:relative;
                        line-height:44px;
                        &:hover {
                            color:white;
                        }
                        &.sf-with-ul:after {
                            opacity:0;
                            position:absolute;
                            top:100%;
                            left:50%;
                            content:"";
                            display:block;
                            width:0;
                            height:0;
                            margin-left:-10px;
                            border-left:solid 10px transparent;
                            border-right:solid 10px transparent;
                            border-top:solid 8px $vert-clair;
                            z-index:100;
                            transition:0.3s;
                        }
                    }
                    &.sfHover>a {
                        color:white;
                        &:after {
                            opacity:1;
                        }
                    }
                    &:first-of-type>a {
                        font-weight:bold;
                    }
                    @media (max-width:991px) {
                        margin-right:18px;
                        &:first-of-type {
                            display:none;
                        }
                    }
                }
                .submenu-container {
                    top:100%;
                    padding:25px 0;
                    box-shadow:none;
                    -webkit-column-count:5;
                       -moz-column-count:5;
                            column-count:5;
                    -webkit-column-gap:15px;
                            column-gap:15px;
                    @media (max-width:1199px) {
                        -webkit-column-count:4;
                           -moz-column-count:4;
                                column-count:4;
                    }
                    @media (max-width:991px) {
                        -webkit-column-count:3;
                           -moz-column-count:3;
                                column-count:3;
                    }
                    &:before {
                        position:absolute;
                        top:0;
                        left:50%;
                        content:"";
                        display:block;
                        width:100vw;
                        height:100%;
                        margin-left:-50vw;
                        background:white;
                        border-bottom:solid 1px lightgray;
                        z-index:-1;
                    }
                    >li {
                        display:inline-block;
                        float:none;
                        width:100%;
                        margin:2px 0 10px 0;
                        >a {
                            font-weight:bold;
                            line-height:0;
                        }
                        >ul {
                            padding:2px 0 0 6px;
                            >li {
                                line-height:8px;
                                a {
                                    display:inline-block !important;
                                    text-transform:lowercase;
                                    &:before {
                                        padding-right:6px;
                                        font-style:normal;
                                    }
                                    span {
                                        display:inline-block;
                                        &:first-letter {
                                            text-transform:lowercase;
                                        }
                                    }
                                }
                                >ul {
                                    display:none !important; 
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width:767px) {
        >.container {
            justify-content:center;
            align-items:flex-end;
            padding:0;
        }
        #links_block_left {
            margin-top:6px;
            padding:0 15px;
        }
        #header_logo {
            margin-top:15px;
            padding-left:15px;
            img {
                width:100px;
            }
        }
        #search_block_top {
            margin:0;
            padding:0 15px;
            >div {
                max-width:none;
            }
        }
        #header_buttons {
            flex:0 1 100%;
            margin-top:10px;
            padding:0 15px;
        }
        #block_top_menu {
            margin-top:25px;
            padding:0;
            .cat-title {
                padding:0 15px;
                background:$vert-clair;
                border:none;
                font-size:18px;
                line-height:50px;
                color:white;
                &:after {
                    top:0;
                    right:0;
                    width:50px;
                    height:50px;
                    font-size:20px;
                    line-height:54px;
                    text-align:center;
                }
            }
            .sf-menu {
                border:none;
                >li {
                    margin:0;
                    border-bottom:solid 1px lightgray;
                    >a {
                        padding:0 15px;
                        background:white !important;
                        border:none;
                        font-weight:normal;
                        font-size:15px;
                        line-height:40px;
                    }
                    .menu-mobile-grover {
                        top:0;
                        right:0;
                        width:50px;
                        height:40px;
                        line-height:44px;
                        text-align:center;
                        &:after {
                            font-size:18px;
                        }
                    }
                    .menu-mobile {
                        padding:15px 25px 5px;
                        background:$gris-clair;
                        border-top:solid 1px lightgray;
                        >li {
                            padding:0 0 10px;
                            a {
                                font-weight:normal;
                                font-size:14px;
                            }
                            >ul {
                                padding:0;
                                a {
                                    padding:0;
                                }
                            }
                        }
                    }
                }
            }
            .menu-mobile {
                box-shadow: none;
            }
        }
    }
}

/* =============================================
==================== FOOTER ====================
============================================= */

#page {
    .footer-container {
        background: none;
        background-color: $vert-fonce;
        border-top: solid 1px $gris-clair;
        position: relative;
        margin-top: 100px;
        @media (max-width: 579px) {
            margin-top: 200px;
        }
        &:before {
            content: "";
            width: 100%;
            height: 64px;
            background: white;
            position: absolute;
        }
        #footer {
            position: relative;
            padding-bottom: 0;
            .row {
                position: inherit;
                padding-bottom: 80px;
            }
            #reassurancebanner_block {
                position: absolute;
                top: -105px;
                @media (max-width: 579px) {
                    top: -205px;
                    li {
                        width: 50% !important;
                    }
                }
                width:100%;
                text-align:center;
                li {
                    margin: 0;
                    padding: 45px 15px 0 !important;
                    background: center top no-repeat;
                    &:first-of-type {
                        background-image: url('../../img/ico_footer1.jpg');
                    }
                    &:nth-of-type(2) {
                        background-image: url('../../img/ico_footer2.jpg');
                        .title {
                            right: 20px;
                        }
                    }
                    &:nth-of-type(3) {
                        background-image: url('../../img/ico_footer3.jpg');
                    }
                    &:nth-of-type(4) {
                        background-image: url('../../img/ico_footer4.jpg');
                        .title {
                            left: 10px;
                        }
                    }
                    span {
                        width: 100%;
                        font-family: "Droid Sans";
                        color: #595959;
                        &.title {
                            font-weight: bold;
                            position: relative;
                        }
                        &.subtitle {
                            margin-top: -6px;
                        }
                    }
                }
            }
            .shop-phone {
                margin: 12px 15px 0 0;
                font-size: 18px;
                color: $gris-fonce;
                h4 {
                    color: $gris-fonce;
                    float: left;
                    font-weight: normal;
                    margin: 4px 15px 0 0;
                }
                i,
                strong {
                    color: inherit;
                }
                i {
                    border: solid 1px;
                    border-radius: 50%;
                    padding: 0;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                }
                strong {
                    font-weight: normal;
                    font-size: 22px;
                }
            }
            #contact-link {
                float: left;
                border: none;
                margin-top: 11px;
                a {
                    background: $orange;
                    font-size: 14px;
                    padding: 10px 14px 12px;
                    color: white;
                    font-weight: normal;
                    text-transform: uppercase;
                    transition: .3s;
                    i {
                        font-size: 20px;
                        position: relative;
                        top: 1px;
                        margin-right: 8px;
                    }
                    &:hover {
                        color: black;
                    }
                }
            }
            #newsletter_block_left {
                padding: 0 15px;
                margin: 12px 0;
                .block_content {
                    h4 {
                        margin: 0;
                        text-align: right;
                        color: $gris-fonce;
                        font-weight: normal;
                        font-size: 18px;
                    }
                    .form-group {
                        padding: 0;
                        @media (max-width: 767px) {
                            position: absolute;
                            left: 152px;
                            right: 15px;
                            z-index: 1;
                        }
                        .form-control {
                            max-width: none !important;
                            height: 100%;
                            background: none;
                            border-color: $gris-clair;
                            color: lightgray;
                            padding: 10px 15px;
                        }
                        .button-small {
                            background: $orange;
                            color: white;
                            padding: 0;
                            height: 40px;
                            width: 40px;
                            margin-left: -41px;
                            position: relative;
                            left: 1px;
                            transition: .3s;
                            &::before {
                                content: "\f00c";
                                font-size: 22px;
                            }
                            &:hover {
                                color: black !important;
                            }
                        }
                    }
                }
            }
            li {
                padding: 0;
                a {
                    color: #fafafa;
                    font-weight: normal;
                }
            }
            a {
                transition: .3s;
                &:hover {
                    color: $vert-clair;
                }
            }
            @media(max-width:767px) {
                h4 {
                    clear: both;
                }
            }
            #block_various_links_footer {
                position: static;
                h4 {
                    cursor: default;
                }
                @media(min-width:992px) {
                    ul {
                        -webkit-column-count: 3;
                        -moz-column-count: 3;
                        column-count: 3;
                        -webkit-column-gap: 30px;
                        -moz-column-gap: 30px;
                        column-gap: 30px;
                    }
                }
                p {
                    position: absolute;
                    left: 15px;
                    bottom: 30px;
                    color: rgba(255, 255, 255, 0.6);
                    a {
                        color: inherit;
                        transition: .3s;
                        &:hover {
                            color: $vert-clair;
                        }
                    }
                }
            }
            #block_contact_infos {
                border: none;
                img {
                    float: left;
                    margin: 15px 30px 50px 0;
                }
                ul {
                    display: block !important;
                    li {
                        color: #fafafa;
                        line-height: inherit;
                        padding: 0;
                        &:first-of-type {
                            margin-bottom: 8px;
                            font-weight: bold;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width:768px) and (max-width:991px) {
        #reassurancebanner_block {
            +.col-sm-6 {
                width: 52%;
                padding-right: 0;
                .shop-phone {
                    i {
                        font-size: 20px;
                    }
                    strong {
                        font-size: 20px;
                    }
                }
            }
        }
        #newsletter_block_left {
            width: 48%;
            padding-left: 0;
            h4 {
                font-size: 16.5px !important;
                padding-right: 12px;
            }
        }
    }
    @media (max-width:767px) {
        .footer-container {
            &::before {
                height: 118px;
            }
            #contact-link {
                width: 100%;
                text-align: center;
            }
        }
    }
}

/* ==============================================
==================== ACCUEIL ====================
============================================== */

#index {
    @media(min-width:768px) {
        #header {
            position:absolute;
            width:100%;
            background:rgba(0, 0, 0, 0.6);
        }
    }
    #homepage-slider {
        float: none;
        margin: 0;
        padding: 0;
        z-index: 0;
        background: black;
        .bx-wrapper {
            margin: 0 auto !important;
            .homeslider-container {
                height: 100vh;
                background-size: cover !important;
                background-position: center center !important;
                background-repeat: no-repeat;
                img {
                    max-width: none;
                    height: 100%;
                }
                .homeslider-description {
                    left: inherit;
                    top: inherit;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.3);
                    padding: 20px 40px;
                    cursor: default;
                    a {
                            color: $vert-clair;
                    } 
                    a:hover{
                        color:$orange;
                    }
                    h2,
                    h3 {
                        font-size: 30px;
                        font-weight: bold;
                    }
                    h2 {
                        text-transform: uppercase;
                    }
                    h3 {
                        color: $vert-clair;
                        margin: 5px 0 20px;
                    }
                    li {
                        display: inline-block;
                        text-transform: uppercase;
                        margin-right: 20px;
                        list-style: circle;
                        &:last-of-type {
                            margin-right: 0;
                        }
                        a {
                            color: white;
                            font-size: 14px;
                            transition: .3s;
                            &::before {
                                font-family: "icomoon";
                                content: "\e900";
                                color: $vert-clair;
                                font-size: 20px;
                                margin-right: 6px;
                                position: relative;
                                top: 3px;
                            }
                            &:hover {
                                color: $vert-clair;
                            }
                        }
                    }
                }
            }
            .bx-controls-direction {
                display: none; // cache les boutons du slider
            }
        }
    }
    #editorial_block_center {
        position: relative;
        padding-top: 35px;
        .titre_accueil {
            position: absolute;
            width: 300px;
            height: 50px;
            top: -50px;
            right: 15px;
            margin: 0;
            background: white;
            text-align: center;
            line-height: 50px;
            margin: 0;
            @media(max-width: 1199px) {
                width: 250px;
            }
            @media(max-width:991px) {
                position: relative;
                top: 0;
                right: 0;
                text-align: left;
                margin-bottom: 20px;
            }
            a {
                font-family: inherit;
            }
        }
        a {
            transition: .3s;
            &:hover {
                color: $orange;
            }
        }
        img {
            float: left;
            margin: 0 15px 15px 0;
        }
        h3 {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 5px;
        }
        p {
            font-size: 14px;
        }
        @media (max-width:1199px) {
            text-align: center;
            img {
                float: none;
                margin: 0 0 15px;
            }
        }
    }
    #home-page-tabs {
        margin: 0;
        padding: 0;
        border-bottom: solid 1px $gris-clair;
        li {
            border: none;
            margin: 0;
            padding: 0 10px 0 0;
            a {
                background: $gris-clair;
                padding: 8px 30px;
                margin: 0;
                border: solid 1px $gris-clair;
                position: relative;
                top: 1px;
                text-transform: none;
                font-size: 18px;
            }
            &.active a {
                background: white;
                border-bottom: solid 1px white;
                color: $orange;
            }
        }
    }
    .tab-content {
        margin: 20px 0;
    }
    .sfcreab-menu {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
        @media(max-width: 1199px) {
            -webkit-column-count: 3;
            -moz-column-count: 3;
            column-count: 3;
        }
        @media(max-width:991px) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
        >li {
            // display: inline-block;
            break-inside: avoid-column;
            width: 100%;
            a {
                transition: .3s;
                &:hover {
                    color: $orange;
                }
            }
            >a {
                display: block;
                border: solid 1px $gris-clair;
                height: 82px;
                line-height: 82px;
                overflow: hidden;
                transition: .3s;
                position: relative;
                img {
                    float: left;
                }
            }
            >ul {
                padding: 10px 10px 20px;
                ul {
                    display: none;
                }
            }
        }
    }
    .blockmanufacturer {
        overflow: hidden;
        margin: 0;
        .block_content {
            >ul {
                padding: 0 30px 0 0;
                @media (max-width: 991px) {
                    padding-right: 0;
                }
                li {
                    width: 20%;
                    float: left;
                    text-align: center;
                    @media (max-width: 1199px) {
                        width: 25%;
                    }
                    @media (max-width:991px) {
                        width: 33.33%;
                    }
                    @media (max-width:459px) {
                        width: 50%;
                    }
                }
            }
            .manufacturers_all {
                padding-right: 0;
                h3 {
                    font-size: 18px;
                    margin: 0 0 20px;
                    font-weight: bold;
                }
                ul {
                    border-left: solid 1px $gris-clair;
                    padding-left: 15px;
                    margin-left: -15px;
                    overflow: hidden;
                    -webkit-column-count: 3;
                    -moz-column-count: 3;
                    column-count: 3;
                    a {
                        transition: .3s;
                        &:hover {
                            color: $orange;
                        }
                    }
                }
            }
        }
    }
    #cmsinfo_block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: auto;
        height: auto;
        width: auto;
        margin: 0 -15px;
        padding: 0;
        border: none;
        background: none;
        div[class^="col-"] {
            min-height: 0;
            padding: 0 15px;
            border: none;
            img {
                max-width: 400px;
                width: 100%;
            }
            h2 {
                margin: 0 0 15px;
                font-size: 18px;
                font-weight: bold;
            }
            p {
                margin-bottom: 10px;
                text-align: justify;
            }
            a {
                float: left;
                margin-top: 10px;
                padding: 8px 12px;
                border: solid 1px lightgray;
                color: $gris-fonce;
                text-transform: uppercase;
                transition: .3s;
                &:nth-of-type(n+2) {
                    margin-left: 15px;
                }
                &:hover {
                    border-color: $vert-clair;
                    color: $vert-clair;
                }
            }
        }
    }
}

/* ======================================================
==================== PAGES CATÉGORIE ====================
====================================================== */

#category {
    .page-heading {
        margin:0 0 15px;
        padding:0;
    }
    .content_scene_cat {
        display:none;
    }
    #layered_block_left {
        max-height:142px;
        overflow-y:auto;
        margin:-10px 0 30px;
        .title_block {
            display:none;
        }
        #enabled_filters {
            display:none;
        }
        #layered_form {
            overflow:hidden;
            .layered_filter {
                float:left;
                margin:0 30px 0 0;
                border:none;
                .layered_filter_ul {
                    max-height:none;
                    margin:6px 0 0;
                    padding:0;
                    li {
                        padding:0 0 2px;
                        a {
                            position:relative;
                            bottom:2px;
                        }
                    }
                    &#ul_layered_manufacturer_0 {
                        -webkit-column-count:2;
                        -moz-column-count:2;
                        column-count:2;
                    }
                }
            }
        }
    }
    .sortPagiBar {
        margin-bottom:10px;
        border:none;
        label {
            margin:0 8px 0 0;
            padding:0;
            line-height:30px;
        }
        .selector+span {
            margin-left:10px;
            padding:0;
            font-style:italic;
            line-height:30px;
            color:black;
        }
    }
    .top-pagination-content {
        display:none;
    }
    .bottom-pagination-content {
        button {
            background: none;
            border: none;
            transition: .3s;
            span {
                background: none;
                border: solid 1px #d6d4d4;
                padding: 0 15px;
                height: 35px;
                line-height: 34px;
                font-size: 13px;
                color: #7c7c7c;
                i {
                    display: none;
                }
            }
            &:hover {
                span {
                    color: $vert-clair;
                    border-color: $vert-clair;
                }
            }
        }
        .product-count {
            padding-top: 10px;
        }
        #pagination,
        #pagination_bottom {
            float: left;
            width: auto;
            .pagination {
                float: left;
                margin: 0 10px;
                li {
                    >span,
                    a {
                        height: 35px;
                        width: 35px;
                        margin: 0;
                        border: none;
                        background: none;
                        span {
                            height: 100%;
                            background: none;
                            border: solid 1px #d6d4d4;
                            border-right: none;
                            line-height: 32px;
                            padding: 0;
                            font-weight: normal;
                        }
                    }
                    a {
                        &:hover {
                            color: $vert-clair;
                        }
                    }
                    &:nth-last-child(2) {
                        border-right: solid 1px #d6d4d4;
                    }
                    &.active {
                        background: #f2f2f2;
                    }
                    &.pagination_previous,
                    &.pagination_next {
                        margin: 0;
                        span,
                        a {
                            padding: 8px 0 0;
                        }
                        b {
                            display: none;
                        }
                    }
                }
            }
            .showall {
                margin: 0;
            }
        }
    }
}

/* ====================================================
==================== PAGES PRODUIT ====================
==================================================== */

#product {
    .top-hr, #usefull_link_block, .box-security {
        display: none;
    }
    .pb-left-column {
        #image-block {
            padding: 0;
            #view_full_size {
                .span_link {
                    display: none;
                }
            }
        }
    }
    .pb-center-column {
        h1 {
            margin: 0 0 18px;
            padding: 0;
        }
        p {
            margin: 0 0 5px;
            label {
                margin: 0;
                width: 80px;
            }
        }
        #short_description_block {
            #short_description_content {
                padding: 0;
                p {
                    margin: 16px 0 6px;
                    text-align: justify;
                }
            }
        }
    }
    .pb-right-column {
        .box-info-product {
            background: none;
            border: none;
            .content_prices {
                padding: 0;
            }
            .product_attributes {
                padding: 0;
                margin: 8px 0 16px;
                box-shadow: none;
            }
            #add_to_cart {
                padding: 0;
                margin: 0;
                button {
                    background: $orange;
                    border: none;
                    border-radius: 0;
                    width: 100%;
                    transition: .3s;
                    span {
                        border: none;
                        width: 100%;
                        padding: 12px 0;
                        text-align: center;
                        font-size: 18px;
                        font-weight: normal;
                    }
                    &::before,
                    &::after {
                        display: none;
                    }
                    &:hover {
                        span {
                            color: black;
                        }
                    }
                }
            }
            #availability_statut {
                margin: 18px 0 0;
                float: right;
                #availability_value {
                    background: none;
                    border: none;
                    padding: 0;
                    font-size: 14px;
                    &.label-success {
                        color: $vert-clair;
                    }
                    &.label-warning {
                        color: $orange;
                    }
                }
            }
        }
    }
    .page-product-box {
        .page-product-heading {
            margin: 0;
        }
        p {
            text-align: justify;
            &:first-of-type {
                margin-top: 20px;
            }
        }
    }
    .product_reference {
        font-weight:bold;
    }
    #product_article_remp a{
        font-size: 13px;
        padding: 12px 25px;
        background: #9bcb39;
        border: none;
        color: white;
    }
    #product_article_remp a:hover{
        color: #000000;
    }
    .product_article_remp{
        padding-top: 10px;
        a{
            font-weight:bold;
            color: #000000;
            font-family: 'icomoon';
            position: relative;
            padding-left: 50px;
            &:before{
                content: '\e903';
                display: inline-block;
                position: absolute;
                color: $gris-fonce;
                font-size: 2.5em;
                left: .1em;
            } 
            &:after{
                content: '\e904';
                display: inline-block;
                position: absolute;
                color: $orange;
                font-size: 2.5em;
                left: .2em;
            }
        }
    }
    .product_article_remp a:hover{
        color: #9bcb39;
    }
}
    
/* ============================================================
==================== PROCESSUS DE COMMANDE ====================
============================================================ */

#order,
#order-confirmation, 
#module-bankwire-payment,
#module-cheque-payment,
#module-usualpaymentterms-payment {
    .step {
        margin: 30px 0;
        li {
            background: none;
            border: none;
            width: auto;
            padding: 0;
            margin-right: 30px;
            span,
            a {
                border: none;
                padding: 0 !important;
                &::after {
                    display: none;
                }
            }
            span {
                color: lightgray;
            }
            a {
                color: black;
            }
            &.step_current {
                span {
                    color: $vert-clair;
                }
            }
        }
    }
    #cart_summary {
        .label {
            background: none;
            border: none;
            padding: 0;
            &.label-success {
                color: $vert-clair;
            }
            &.label-warning {
                color: $orange;
            }
        }
    }
    .cart_navigation {
        .button-exclusive {
            border: solid 1px lightgray;
            color: #7c7c7c;
            font-weight: normal;
            font-size: 16px;
            padding: 12px 25px;
            margin: 0;
            transition: .3s;
            i {
                transition: .3s;
                &::before {
                    content: "\f104";
                }
            }
            &:hover {
                color: $vert-clair;
                border-color: $vert-clair;
                i {
                    color: $vert-clair;
                }
            }
        }
    }
    .payment_module a {
        background-color: #9bcb39;
        color:#FFF;
    }
    .payment_module a:hover {
            color:#000;
    }
    p.payment_module a.usual { 
        background: url(../../img/bankwire.png) 15px 15px no-repeat; 
        background-color: #9bcb39;
    }
}

/* =================================================
==================== MON COMPTE ====================
================================================= */

#my-account {
    .myaccount-link-list li {
        a {
            background: none !important;
            border-color: lightgray !important;
            border-radius: 0 !important;
            i, span {
                border: none !important;
            }
            i {
                color: $orange !important;
            }
            span {
                padding: 12px 15px !important;
                border-left: solid 1px lightgray !important;
            }
        }
        &:hover span {
            color: orange !important;
        }
    }
}

/* ======================================================
==================== COMMANDE RAPIDE ====================
===================================================== */

#creaexpressorder {
    color: black;
    font-family: 'Open Sans';
    form {
        margin: 40px 0 15px;
        font-style: italic;
        fieldset>div {
            display: inline-block;
            margin-right: 12px;
            font-size: 12px;
            .form-control {
                width: auto;
                height: auto;
                padding: 8px 12px;
                margin-top: 2px;
                border-color: lightgray;
                box-shadow: none;
                font-size: 14px;
                &#qty {
                    width: 60px;
                }
            }
        }
        .button-medium {
            outline: 0;
            margin-top: -5px;
            vertical-align: bottom;
            span {
                background: $orange;
                font-size: 14px;
                padding: 8px 15px;
            }
        }
        p {
            text-align: justify;
            margin-top: 15px;
            font-style: normal;
        }
    }
    .row {
        margin: 0;
        >div {
            padding: 8px 12px;
        }
    }
    .table-heading, #expressorderCartContainer, .table-bottom {}
    .table-heading {
        background: $vert-clair;
    }
    #expressorderCartContainer .row {
        border: solid 1px lightgray;
        border-top: none;
        img {
            margin-right: 10px;
        }
        .label {
            border: none;
            background: none;
            text-align: left;
            &.label-warning {
                color: $orange;
            }
            &.label-success {
                color: $vert-clair;
            }
        }
        span {
            color: black;
        }
    }
    .table-bottom {
        margin-bottom: 25px;
        >div {
            border: solid 1px lightgray;
            border-top: none;
            font-weight: bold;
        }
    }
}

/* ==========================================================
==================== COMMANDE PAR ÉCLATÉ ====================
========================================================== */

#module-creapartstream-PartStream {
    #ariPartStream {
        margin-top: 30px;
        * {
            font-family: 'Open Sans' !important;
            font-weight: normal;
            color: black;
            outline: 0;
        }
        .ariMainTitle {
            text-transform: uppercase;
            margin: 0 0 10px !important;
            font-size: 16px;
        }
        .ariMainLabel {
            font-style: italic;
            font-size: 12px;
            margin-bottom: 2px;
            padding: 0;
        }
        .ariDropDown {
            border: none;
            a {
                padding: 8px 12px;
                font-weight: lighter;
                background: url('../../img/fleche_eclate.png') no-repeat 100% 50%;
                border: solid 1px lightgray;
            }
            .ari-caused-spacer-expand {
                border-color: lightgray;
                border-top: none;
                padding: 8px 0;
                position: absolute;
                li {
                    padding: 3px 12px;
                    &:hover {
                        background: $gris-clair;
                    }
                }
            }
        }
        .ariInputs {
            border: solid 1px lightgray;
            padding: 8px 12px;
            font-size: 12px;
        }
        .ariSearchBrandWidth {
            margin: 0 20px 0 0;
        }
        #arisearch_btnLookup {
            border: solid 1px transparent;
            background: $orange;
            padding: 8px 15px;
            font-size: 14px;
            color: white;
            transition: .3s;
            &:hover {
                color: black;
            }
        }
        .ariHR {
            background: lightgray;
            margin: 30px 0;
        }
    }
    #ariPSSearchReasults_Page, #ariPartList {
        border: none;
        .ariPSSearchResults_header {
            padding: 0;
            margin-bottom: 15px;
            #ariPSSearchResults_NewModelBtnDiv {
                float: right !important;
                #ariparts_btnModelSearchNewModel {
                    @extend #arisearch_btnLookup;
                }
            }
            #ariPSSearchResults_DescriptionHeadingDiv {
                width: auto;
                font-style: italic;
                padding: 0 !important;
                position: relative;
                top: 25px;
                font-size: 12px;
                font-weight: normal;
                color: $gris-fonce;
            }
        }
        ins {
            display: none;
        }
        td {
            border: none;
            padding: 8px 12px;
            font-size: 12px;
            img {
                height: 22px !important;
                margin: 3px 0 !important;
            }
            input {
                border: solid 1px lightgray;
            }
            .ariPartListAddToCart {
                @extend #arisearch_btnLookup;
                font-size: 12px !important;
                padding: 4px 10px !important;
            }
            .viewModels {
                transition: .3s;
                &::before {
                    content: "\f105";
                    font-family: 'FontAwesome';
                }
                &:hover {
                    color: $vert-clair;
                }
            }
        }
        thead {
            background: $vert-clair;
            border: none;
            tr {
                border: solid 1px $vert-clair;
                .listTD {
                    border: none;
                    padding: 8px 12px;
                    &:first-of-type {
                        width: 15% !important;
                    }
                }
            }
        }
        tbody {
            tr {
                background: none;
                border: solid 1px lightgray;
                border-top: none;
                .listTD {
                    position: relative;
                    .partStreamLoading {
                        width: 12px !important;
                        height: 12px !important;
                        position: absolute;
                        right: -5px;
                        top: 14px;
                    }
                }
            }
        }
        .ari_searchResults_GridPaging {
            padding: 10px 0 0;
            .ariPagingPageNofMSectionClass {
                padding: 0 !important;
            }
            .ari_searchResults_GridPageDescription {
                float: right !important;
                width: auto !important;
                .ariPagingSummarySectionClass {
                    font-size: 12px;
                    font-style: italic;
                    color: $gris-fonce;
                    span {
                        color: inherit;
                    }
                }
            }
        }
    }
    #ariAssembly {
        margin-top: 50px;
    }
    #ariSearchSupersesionToolTip, #ariSupersesionToolTip {
        padding: 15px !important;
        #ariSearchSupersesionToolTipTitle,
        #ariSupersesionToolTipTitle {
            font-size: 12px;
            color: $orange;
        }
        input {
            color: red;
        }
        #ariSearchSupersesionToolTipInfo,
        #ariSupersesionToolTipInfo {
            margin: 0 !important;
            #ariSearchSupersesionToolTipLabel,
            #ariSupersesionToolTipLabel {
                font-size: 12px;
                margin: 5px 0 4px;
            }
            .ariSupersesionToolTipPartInfoClass {
                display: inline-block;
                padding: 5px 8px !important;
                background: $gris-clair;
            }
        }
    }
    #ariSearchSupersesionToolTip {
        width: 300px !important;
    }
    #ariSupersesionToolTip {
        width: 390px !important;
    }
    #ariBackToSearch {
        @extend #arisearch_btnLookup;
        position: absolute;
        right: 0;
        top: -25px;
    }
    #ariAssemblyLabelContent {
        @extend .ariMainLabel;
    }
    #ari_Assemblies_jl {
        width: 100% !important;
        margin-bottom: 30px;
    }
    #ariInfoZoom {
        float: right;
        width: auto;
        margin-bottom: 10px;
        input {
            float: right;
            background: none;
            border: solid 1px lightgray;
            padding: 8px 12px;
            font-size: 13px;
            text-transform: uppercase;
            color: $gris-fonce;
            transition: .3s;
            &:hover {
                color: $vert-clair;
                border-color: $vert-clair;
            }
            &#ariZoomOut {
                margin-right: 10px;
            }
        }
    }
    #ariPartImage {
        border: solid 1px lightgray;
        .ariHotSpot {
            background: $vert-clair;
            border-color: $vert-clair;
            opacity: 0.5;
            &:hover {
                background: $orange;
                border-color: $orange;
            }
        }
    }
    #ariHotSpotToolTip {
        width: 280px !important;
        padding: 15px !important;
        #ariHotSpotToolTipTitle {
            line-height: 24px;
        }
        #ariHotSpotToolTipImage {
            width: 100% !important;
            height: auto !important;
            padding: 10px 0 22px !important;
            img {
                margin: 0 !important;
            }
        }
        #ariHotSpotToolTipInfo {
            width: 100% !important;
            text-align: center !important;
            padding: 0 !important;
            >div {
                text-align: center !important;
                &:first-of-type {
                    margin: 0 0 15px !important;
                }
                .ariToolTipPartNumber {
                    padding: 5px 8px;
                    background: $gris-clair !important;
                }
                #ariHotSpotToolTipQtyLabel {
                    padding: 0 !important;
                }
                .ariHotSpotToolTipQty {
                    border: solid 1px lightgray;
                }
                .ariPartListAddToCart {
                    @extend #arisearch_btnLookup;
                }
            }
            .partStreamLoading {
                width: 12px !important;
                height: 12px !important;
                position: absolute;
                right: 80px;
                bottom: 28px;
            }
        }
    }
}

/* =============================================
==================== AUTRES ====================
============================================= */

#ariPartSelectPanel {
    .ariSearchButtonWidth {
        input[type="button"] {
            border: 0px;
            background-color: $orange;
        }
    }
}
#page {
    #ariPartList .ari_row_odd {
        background-color: #fff;
    }
    .ariPartListAddToCart {
        border: 0px;
        background-color: $orange;
    }
}

/* ==============================================
==================== CONTACT ====================
============================================== */

#page .contact-form-box {
    background-image:none;
    box-shadow:none;
    fieldset {
        background-image:none;
    }
}
#cart_summary tfoot td#total_product {
    font: 600 21px/25px "Open Sans", sans-serif;
    color: #333;
}